import React from "react";
import SEO from "../components/seo/Seo";

import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import { v4 } from "uuid";

class TagRoute extends React.Component {
  renderBox = (postObj) => {
    return (
      <div
        key={v4()}
        className="box"
        style={{ width: "100%", wordBreak: "break-word" }}
      >
        <article className="media">
          <div className="media-content">
            <div className="content">
              <Link to={postObj.fields.slug}>
                {postObj.frontmatter.title ? (
                  <h2 className="is-size-2 has-text-primary">
                    {postObj.frontmatter.title}
                  </h2>
                ) : (
                  <h2 className="is-size-2 has-text-primary">
                    {postObj.frontmatter.recipename}
                  </h2>
                )}
              </Link>
              <p>{postObj.excerpt}</p>
            </div>
            {/* <nav className="level is-mobile">
              <div className="level-left">
                <a className="level-item" aria-label="reply">
                  <span className="icon is-small">
                    <i className="fas fa-reply" aria-hidden="true"></i>
                  </span>
                </a>
                <a className="level-item" aria-label="retweet">
                  <span className="icon is-small">
                    <i className="fas fa-retweet" aria-hidden="true"></i>
                  </span>
                </a>
                <a className="level-item" aria-label="like">
                  <span className="icon is-small">
                    <i className="fas fa-heart" aria-hidden="true"></i>
                  </span>
                </a>
              </div>
            </nav> */}
          </div>
        </article>
      </div>
    );
  };

  render() {
    const posts = this.props.data.allMarkdownRemark.edges;

    const postLinks = posts.map((post) => this.renderBox(post.node));
    const tag = this.props.pageContext.tag;
    const tagHeader = `Beiträge markiert mit ${tag}`;
    return (
      <Layout>
        <SEO
          title={tagHeader}
          description={`Übersicht aller ${tagHeader} rund um das Thema Fructosintoleranz, Histaminintoleranz, Lactosintoleranz und Multi-Intoleranz generell`}
          image={this.props.data.file.childImageSharp.resize}
          pathname={this.props.location.pathname}
        />

        <section className="section">
          <div className="container content">
            <div className="columns">
              <div
                className="column is-10 is-offset-1"
                style={{ marginBottom: "6rem" }}
              >
                <h3 className="title is-size-4 is-bold-light">{tagHeader}</h3>
                <ul className="taglist">{postLinks}</ul>
                <p>
                  <Link to="/tags/">Alle tags durchsuchen...</Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 200)
          fields {
            slug
          }
          frontmatter {
            title
            recipename
          }
        }
      }
    }
    file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }
  }
`;
